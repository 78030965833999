/* src/CharacterList.css */
.character-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  
  @media (max-width: 768px) {
    .character-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .character-card {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    background-color: #f9f9f9;
    transition: transform 0.2s;
  }
  
  .character-card:hover {
    transform: scale(1.05);
  }
  
  .character-image {
    width: 100%;
    height: auto;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .pagination button {
    margin: 0 2px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #fff;
    transition: background-color 0.2s;
  }
  
  .pagination button:hover {
    background-color: #f0f0f0;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .page-number {
    margin: 0 2px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #fff;
    transition: background-color 0.2s;
  }
  
  .page-number.active {
    font-weight: bold;
    background-color: #f0f0f0;
  }
  